export default {
    title: 'Réinitialiser le mot de passe',
    steps: [
      {
        number: '1',
        description: 'Entrez votre adresse e-mail',
      },
      {
        number: '2',
        description: "Ouvrez le lien dans l'e-mail que vous recevez",
      },
      {
        number: '3',
        description: 'Choisissez un nouveau mot de passe',
      },
    ],
    button: 'Réinitialiser le mot de passe',
    success: {
      icon: 'account_circle',
      title: "Finalisez votre changement de mot de passe",
      content:
        '<span class="text-bold"> Un e-mail vient de vous être envoyé.</span><br>Pour finaliser la réinitialisation de votre mot de passe, rendez-vous dans votre boite e-mail et cliquez sur le lien. <br>',
      button: {
        text: "J'ai compris",
        route: 'Home',
      },
    },
    error: {
      icon: 'account_circle',
      title: 'Une erreur s\'est produite',
      content: 'Une erreur s\'est produite lors de la réinitalisation de votre mot de passe.<br>Veuillez contacter un administrateur.<br>',
      button: {
        text: 'Retour à l\'accueil',
        route: 'Home',
      },
    },
  };
  